import React from 'react'
import { Link } from 'gatsby'
import _ from 'lodash'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

export default () => (
  <div>
    <p>
      <FormattedMessage id="solutions.llave.content.p1" />
    </p>
    <h2>
      <FormattedMessage id="solutions.llave.content.subtitle" />
    </h2>
    <ul>
      {_.map(_.times(12, String), (value, key) => (
        <li key={key}>
          <FormattedHTMLMessage
            id={`solutions.llave.content.list.item${value}`}
          />
        </li>
      ))}
    </ul>
    <p>
      <b>
        <FormattedHTMLMessage id="solutions.llave.content.label_link" />
      </b>
      <Link to="/constituir-empresa">
        <FormattedHTMLMessage id="solutions.llave.content.link" />
      </Link>
    </p>
    <p style={{ textAlign: 'center' }}>
      <FormattedMessage id="solutions.llave.content.cost" />
    </p>
    <p>
      <FormattedMessage id="solutions.llave.content.terms" />
    </p>
  </div>
)
