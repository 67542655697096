import React from 'react'
import _ from 'lodash'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

export default () => (
  <div>
    <p>
      <FormattedMessage id="solutions.as_constitucion.content.p1" />
    </p>
    <h2>
      <FormattedMessage id="solutions.as_constitucion.content.subtitle1" />
    </h2>
    <ul>
      {_.map(_.times(8, String), (value, key) => (
        <li key={key}>
          <FormattedHTMLMessage
            id={`solutions.as_constitucion.content.list1.item${value}`}
          />
        </li>
      ))}
    </ul>
    <h2>
      <FormattedMessage id="solutions.as_constitucion.content.subtitle2" />
    </h2>
    <ul>
      {_.map(_.times(4, String), (value, key) => (
        <li key={key}>
          <FormattedHTMLMessage
            id={`solutions.as_constitucion.content.list2.item${value}`}
          />
        </li>
      ))}
    </ul>
    <p style={{ textAlign: 'center' }}>
      <FormattedMessage id="solutions.as_constitucion.content.cost" />
    </p>
    <p>
      <FormattedMessage id="solutions.as_constitucion.content.terms" />
    </p>
  </div>
)
