import React from 'react'
import { Row, Col } from 'react-styled-flexboxgrid'
import { DividerContainer, DividerColContent } from './Divider.styled'

const Divider = ({
  children,
  size = '',
  border = '',
  align = false,
  small = false,
  ...restProps
}) => (
  <DividerContainer size={size} border={border} small={small} {...restProps}>
    <Row
      center="xs"
      middle="xs"
      style={{ minHeight: !small ? '142px' : '80px' }}
    >
      <Col xs={11} lg={9}>
        <DividerColContent align={align} small={small}>
          {children}
        </DividerColContent>
      </Col>
    </Row>
  </DividerContainer>
)

export default Divider
