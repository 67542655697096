import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import {
  ContainerSolutions,
  ButtonSolution,
  Content,
  Text,
} from './Solutions.styled'
import { FormattedMessage } from 'react-intl'
import { Link } from 'gatsby'

const Solutions = ({ solutionsData }) => {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "solutions" } }) {
        edges {
          node {
            name
            childImageSharp {
              fixed(height: 220) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `)
  return (
    <ContainerSolutions>
      {solutionsData.map((data, index) => {
        const { title } = data
        const { node = null } =
          edges.find(({ node }) => node.name === title) || {}
        return (
          <ButtonSolution key={index}>
            <Img alt={title} fixed={node && node.childImageSharp.fixed} />
            <Link style={{ color: 'white' }} to={`/${title}`}>
              <Content>
                <h2>
                  <FormattedMessage id={`solutions.${title}.title`} />
                </h2>
                <Text>
                  <FormattedMessage
                    id={`solutions.${title}.shortDescription`}
                  />
                </Text>
              </Content>
            </Link>
          </ButtonSolution>
        )
      })}
    </ContainerSolutions>
  )
}

export default Solutions
