import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './styles.css';

class Carousel extends Component {
	constructor(props) {
		super(props);
		this.slider2Ref = React.createRef();
	}
	static propTypes = {
		autoPlay: PropTypes.bool,
		disableButtons: PropTypes.bool
	};

	static defaultProps = {
		autoPlay: false,
		disableButtons: false
	};

	componentDidMount() {
		const { autoPlay, groupCells } = this.props;
		if (typeof window !== undefined) {
			const Flickity = require('flickity');
			this.flkty = new Flickity(this.slider2Ref.current, {
				lazyLoad: true,
				imagesLoaded: true,
				wrapAround: true,
				autoPlay,
				groupCells
			});
		}
	}
	render() {
		const { disableButtons } = this.props;
		return (
			<div className={disableButtons ? 'disable-buttons' : ''} style={{ width: '100%' }} ref={this.slider2Ref}>
				{this.props.children}
			</div>
		);
	}
}

export default Carousel;
