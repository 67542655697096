import React from 'react'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'

export default () => (
  <div>
    <p>
      <FormattedMessage id="solutions.fiscal.content.p1" />
    </p>
    <p>
      <FormattedMessage id="solutions.fiscal.content.p2" />
    </p>
    <h2>
      <FormattedMessage id="solutions.fiscal.content.subtitle" />
    </h2>
    <ul>
      {_.map(_.times(12, String), (value, key) => (
        <li key={key}>
          <FormattedMessage id={`solutions.fiscal.content.list.item${value}`} />
        </li>
      ))}
    </ul>
    <p>
      <FormattedMessage id="solutions.fiscal.content.p3" />
    </p>
    <p>
      <FormattedMessage id="solutions.fiscal.content.p4" />
    </p>
  </div>
)
