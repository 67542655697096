import styled, { css } from 'styled-components'
import { Col } from 'react-styled-flexboxgrid'

import { BP_MEDIUM, BP_SMALL } from '../../styles/theme'

const DividerContainer = styled(Col)`
  background-color: ${props => props.theme.primary};
  position: relative;
  ${props => {
    let border = props.border || 'none'
    switch (border) {
      case 'right':
        border = '0 100px 100px 0'
        break
      case 'left':
        border = '100px 0 0 100px'
        break
      case 'both':
        border = '100px'
        break
      default:
        border = '0'
    }
    return css`
      border-radius: ${border};
    `
  }};

  ${props =>
    props.small &&
    css`
      max-width: 70%;
      margin-left: ${props.border === 'left' ? 'auto' : 0};
      margin-right: ${props.border === 'right' ? 'auto' : 0};
      margin-top: 1rem;
      @media only screen and (max-width: ${BP_MEDIUM}) {
        max-width: 100%;
      }
    `}
`

const DividerColContent = styled(Col)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* align-items: flex-start; */
  padding: 0.5rem 1rem;
  @media only screen and (max-width: ${BP_MEDIUM}) {
    padding: 0;
  }
  @media only screen and (max-width: ${BP_SMALL}) {
    align-items: center;
    flex-direction: column;
    padding: 1.5rem 0;
  }
  color: ${props => props.theme.textLight};

  ${props =>
    props.align &&
    css`
      align-items: center;
    `};
  ${props =>
    props.small &&
    css`
      justify-content: space-evenly;
    `}
`

export { DividerContainer, DividerColContent }
