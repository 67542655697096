import React from 'react'
import _ from 'lodash'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

export default () => (
  <div>
    <p>
      <FormattedMessage id="solutions.desarrollo.content.p1" />
    </p>
    <ul>
      {_.map(_.times(2, String), (value, key) => (
        <li key={key}>
          <FormattedHTMLMessage
            id={`solutions.desarrollo.content.list.item${value}`}
          />
        </li>
      ))}
    </ul>
    {_.map(_.times(14, String), (value, key) => (
      <p key={key}>
        <FormattedMessage
          id={`solutions.desarrollo.content.second_list.item${value}`}
        />
      </p>
    ))}
    <ul>
      {_.map(_.times(2, String), (value, key) => (
        <li key={key}>
          <FormattedHTMLMessage
            id={`solutions.desarrollo.content.third_list.item${value}`}
          />
        </li>
      ))}
    </ul>
  </div>
)
