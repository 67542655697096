import React from 'react'
import _ from 'lodash'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

export default () => (
  <div>
    <h2>
      <FormattedHTMLMessage id="solutions.prevencion.content.title" />
    </h2>
    {_.map(_.times(3, String), (value, key) => (
      <p key={key}>
        <FormattedHTMLMessage id={`solutions.prevencion.content.p${value}`} />
      </p>
    ))}
    <ul>
      {_.map(_.times(7, String), (value, key) => (
        <li key={key}>
          <FormattedMessage
            id={`solutions.prevencion.content.list.item${value}`}
          />
        </li>
      ))}
    </ul>
  </div>
)
