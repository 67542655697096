import React from 'react'
import _ from 'lodash'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

export default () => (
  <div>
    <p>
      <FormattedHTMLMessage id="solutions.legal.content.p1" />
    </p>
    <ul>
      {_.map(_.times(4, String), (value, key) => (
        <li key={key}>
          <FormattedMessage id={`solutions.legal.content.list.item${value}`} />
        </li>
      ))}
    </ul>
    <h3 style={{ textAlign: 'center' }}>
      <FormattedMessage id="solutions.legal.content.p2" />
    </h3>
  </div>
)
