import styled from 'styled-components';
import hex2rgba from '../../utils/hex2rgba';

const ContainerSolutions = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`;
const Text = styled.p`
	opacity: 0;
	font-size: 1rem;
	transition: opacity 300ms ease-in;
	text-align: center;
	display: -webkit-box;
	-webkit-line-clamp: 6;
	-webkit-box-orient: vertical;
	overflow: hidden;
`;
const Content = styled.div`
	position: absolute;
	top: 0;
	padding: 1rem;
	background-color: ${(props) => hex2rgba(props.theme.primary, 0.3)};
	width: 100%;
	height: 100%;
`;

const ButtonSolution = styled.button`
	cursor: pointer;
	font-family: 'Lato', 'sans-serif';
	width: 220px;
	height: 220px;
	margin: 1rem;
	border: none;
	border-radius: 30px;
	box-shadow: var(--shadow-light);
	color: ${(props) => props.theme.textLight};
	overflow: hidden;
	padding: 0;
	position: relative;

	&:focus {
		outline: none;
	}
	&:hover {
		${Text} {
			opacity: 1;
		}
	}
`;

export { ContainerSolutions, ButtonSolution, Content, Text };
