import React from 'react'
import _ from 'lodash'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

export default () => (
  <div>
    <p>
      <FormattedMessage
        id="solutions.contable.content.p1"
        values={{ companyName: <b>AS Consultores</b> }}
      />
    </p>
    <p>
      <FormattedHTMLMessage id="solutions.contable.content.p2" />
    </p>
    <h2>
      <FormattedMessage id="solutions.contable.content.subtitle" />
    </h2>
    <ul>
      {_.map(_.times(5, String), (value, key) => (
        <li key={key}>
          <FormattedHTMLMessage
            id={`solutions.contable.content.list.item${value}`}
          />
        </li>
      ))}
    </ul>
    <p>
      <FormattedHTMLMessage id="solutions.contable.content.p3" />
    </p>
  </div>
)
