import React from 'react'

// import {
//   administracion,
//   auditoria,
//   contable,
//   desarrollo,
//   facturacion,
//   fiscal,
//   laboral,
//   legal,
//   llave,
//   prevencion,
//   publicidad,
//   seguridad,
// } from '../../images/solutions'
// import as_constitucion from '../../images/Garantía-servicios-contables.jpg';
import {
  Contable,
  Fiscal,
  Laboral,
  Seguridad,
  Facturacion,
  Llave,
  Constitucion,
  Administracion,
  Legal,
  Publicidad,
  Auditoria,
  Prevencion,
  Desarrollo,
} from './Contents'

export default [
  {
    title: 'contable',
    // image: contable,
    contentModal: <Contable />,
  },
  {
    title: 'fiscal',
    // image: fiscal,
    contentModal: <Fiscal />,
  },
  {
    title: 'laboral',
    // image: laboral,
    contentModal: <Laboral />,
  },
  {
    title: 'seguridad',
    // image: seguridad,
    contentModal: <Seguridad />,
  },
  {
    title: 'facturacion',
    // image: facturacion,
    contentModal: <Facturacion />,
  },
  {
    title: 'llave',
    // image: llave,
    contentModal: <Llave />,
  },
  {
    title: 'as_constitucion',
    // image: as_constitucion,
    contentModal: <Constitucion />,
  },
  {
    title: 'administracion',
    // image: administracion,
    contentModal: <Administracion />,
  },
  {
    title: 'legal',
    // image: legal,
    contentModal: <Legal />,
  },

  {
    title: 'publicidad',
    // image: publicidad,
    contentModal: <Publicidad />,
  },
  {
    title: 'financial-sector',
  },
  {
    title: 'auditoria',
    // image: auditoria,
    contentModal: <Auditoria />,
  },
  {
    title: 'prevencion',
    // image: prevencion,
    contentModal: <Prevencion />,
  },
  {
    title: 'desarrollo',
    // image: desarrollo,
    contentModal: <Desarrollo />,
  },
  {
    title: 'as-health',
  },
]
